import styled, { css } from 'styled-components'
import get from 'lodash/get'

import themeColor from 'lij/themeColor'
import themeProp from 'lij/themeProp'
import media from 'lij/media'

function handleStyle(value) {
  if (typeof value === 'object') {
    return themeProp(value.style || 'solid')
  } else if (value) {
    return props => {
      return (themeColor.locked(value)(props)) ? 'solid' : 'none'
    }
  } else {
    return 'none'
  }
}

function handleWidth(value) {
  if (typeof value === 'object') {
    return themeProp(value.width || '1px')
  } else if (value) {
    return '1px'
  } else {
    return 'initial'
  }
}

function handleColor(value) {
  if (typeof value === 'object') {
    return themeColor.locked(value.color || 'none')
  } else if (value) {
    return themeColor.locked(value)
  } else {
    return 'initial'
  }
}

function handleImage(value, prop) {
  return props => {
    const defaultSource = 'initial'
    const defaultSlice = 'initial'
    const defaultRepeat = 'repeat'

    const defaultImage = {
      source: defaultSource,
      slice: defaultSlice,
      repeat: defaultRepeat,
    }

    let all = {}

    if (typeof value === 'string') {
      all = defaultImage
    }
    
    if (typeof value === 'object') {
      const image = value.image || {}
      all = { ...defaultImage, ...image }
    }

    return all[prop]
  }
}

const border = bg => css`
  border-style: ${handleStyle(bg)};
  border-color: ${handleColor(bg)};
  border-width: ${handleWidth(bg)};
  border-image-source: ${handleImage(bg, 'source')};
  border-image-slice: ${handleImage(bg, 'slice')};
  border-image-repeat: ${handleImage(bg, 'repeat')};

  ${handleMedia(bg)}
`

function handleMedia(value) {
  if (typeof value === 'object' && typeof value.media === 'object') {
    return Object.keys(value.media).reduce((str, key) => {
      const bg = value.media[key]
      return css`
        ${str}
        ${media(key)`
          ${border(bg)}
        `}
      `
    }, '')
  }
}

export const getBorderTheme = (defaults) => props => {
  return border(get(props.theme, props.border, props.border || defaults))
}

const Border = styled.div`
  ${getBorderTheme()}
`

export default Border