import Color from 'lij/Color'

export const clear = new Color({
  base: 'rgba(0,0,0,0)'
})

export const white = new Color({
  base: 'white'
})

export const black = new Color({
  base: 'black'
})

export const grey = new Color({
  lightest: '#f3f4f6',
  lighter: '#d5d9df',
  light: '#b8bfc9',
  base: '#96a1af',
  dark: '#667487',
  darker: '#4b5563',
  darkest: '#3f4753'
})

export const blue = new Color({
  base: '#4782F2',
  lighter: '#A5CCFA'
})

export const purple = new Color({
  base: '#9782F2',
})

export const green = new Color({
  base: '#52b788'
})

export const red = new Color({
  base: '#ef233c',
})

export const orange = new Color({
  base: '#ff6e60',
})

export const peach = new Color({
  base: '#FAE2D4'
})

export const fluorescent = new Color({
  green: '#9efd38'
})

export const mountain = new Color({
  lightest: '#CCAFD0',
  lighter: '#8F8CB6',
  light: '#777AA6',
  base: '#656BA0',
  dark: '#5A5C8F',
  darker: '#484565',
  darkest: '#473B4A',
  text: '#b5c0e9',
})
