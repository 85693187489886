import * as colors from 'frame/colors'
import 'frame/setupIcons'

import desktop3x from 'img/mountain-bg-desktop-3x.webp'
import desktop1x from 'img/mountain-bg-desktop-1x.webp'
import mobile3x from 'img/mountain-bg-mobile-3x.webp'
import mobile2x from 'img/mountain-bg-mobile-2x.webp'
import mobile1x from 'img/mountain-bg-mobile-1x.webp'
import repeatedBg from 'img/mountain-bg-bottom-repeat.png'

const theme = {
  colors,
  text: {
    span: {
      display: "inline"
    },
    h1: {
      size: "4rem",
      weight: "600",
      margin: "2rem 0"
    },
    h2: {
      size: "3.4rem",
      weight: "600",
      margin: "2rem 0"
    },
    p: {
      size: "2.2rem",
      lineHeight: "3rem",
      margin: "1rem 0",
      color: "mountain.darker"
    },
    label: {},
    a: {
      display: "inline",
      color: "blue"
    },
    button: {
      background: "blue",
      color: "white",
      transform: "capitalize"
    }
  },
  frame: {
    background: {
      image: [
        {
          imageSet: [
            {
              url: desktop1x,
              res: "1x"
            },
            {
              url: desktop3x,
              res: "3x"
            }
          ],
          size: "max(1700px, 100vw)",
          repeat: "no-repeat",
          attachment: "fixed",
          position: "center -350px"
        },
        {
          url: repeatedBg,
          size: "max(1700px, 100vw)",
          repeat: "repeat-y",
          position: "center"
        }
      ],
      media: {
        mobile: {
          image: [
            {
              imageSet: [
                {
                  url: mobile1x,
                  res: "1x"
                },
                {
                  url: mobile2x,
                  res: "2x"
                },
                {
                  url: mobile3x,
                  res: "3x"
                }
              ],
              size: "600px",
              repeat: "no-repeat",
              attachment: "fixed",
              position: "0 -260px"
            },
            {
              url: repeatedBg,
              size: "1300px",
              repeat: "repeat-y",
              position: "0 0"
            }
          ]
        }
      }
    },
    page: {
      maxWidth: "84rem",
      background: "white",
      shadow: "black@.5",
      borderRadius: "0 0 0.5rem 0.5rem",
      margin: "0 auto 2rem",
      padding: "2rem",
      wrapperPadding: "0 2rem",
      title: {
        shadow: "clear",
        margin: "0"
      }
    },
    header: {
      scrolled: {
        background: "black",
        shadow: "black",
        title: {
          color: "white"
        },
        logo: {
          color: "white"
        },
        menu: {
          color: "white"
        }
      },
      unscrolled: {
        background: "clear",
        title: {
          color: "mountain.dark"
        },
        logo: {
          color: "mountain.dark"
        },
        menu: {
          color: "mountain.dark"
        }
      }
    },
    menu: {
      background: "mountain.darker@.95",
      gridGap: "2rem",
      stickyTop: "8rem",
      closeButton: {
        background: "clear",
        color: "mountain.lighter"
      },
      button: {
        enabled: {
          background: {
            color: "mountain.light"
          },
          shadow: "black",
          size: "2rem",
          color: "white"
        },
        disabled: {
          background: "mountain.dark@.5",
          border: "mountain.light@.5",
          shadow: "black",
          size: "2rem",
          color: "mountain.light"
        }
      }
    },
    builtBy: {
      logo: "white",
      overtext: "white@.8",
      company: "white",
      undertext: "mountain.lightest",
      ga: "mountain.lightest"
    }
  },
  components: {
    loader: {
      color: "mountain"
    }
  }
}

export default theme