import { createGlobalStyle } from 'styled-components/macro'
import { normalize } from 'polished'

let height
let userAgent = window.navigator.userAgent;

if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
  height = `${window.innerHeight}px`
}
else {
  height = '100vh'
}


const GlobalStyle = createGlobalStyle`
  ${normalize()}
  :root {
    font-size: 10px;
    --app-height: ${height};
  }
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    font-family: "PT Sans";
    
    * {
      box-sizing: border-box;
    }
  }
  body {
    font-size: 1.4rem;
    width: 100%;
    height: 100%;
    touch-action: pan-x pan-y;
    overflow: hidden;
  }
  #root {
    height: 100%;
  }
  img, svg {
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

export default GlobalStyle