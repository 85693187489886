import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'
import axios from 'axios'

import Loader from 'lij/Loader'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const LoaderWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`

const CustomContext = createContext()

function useWebsite() {
  const [website, setWebsite] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (window.parent && window.parent !== window) {
      console.log('LISTENING FOR MESSAGES')
      const handleMessage = event => {
        console.log('-> GOT MESSAGE', event)
        if (event.data && event.data.name === 'SET_WEBSITE') {
          setWebsite(event.data.payload)
        }
        if (event.data && event.data.name === 'SET_WEBSITE_URL') {
          history.push(event.data.payload)
        }
      }
      window.addEventListener('message', handleMessage)
      window.parent.postMessage({ name: 'REQUEST_WEBSITE' },'*')
      return () => {
        console.log('UNLISTENING FOR MESSAGES')
        window.removeEventListener('message', handleMessage)
      }
    } else {
      api.get(`/websites/${process.env.REACT_APP_WEBSITE_ID}`).then(resp => {
        setWebsite(resp.data)
      }).catch(() => {
        setWebsite({})
      })
    }
  }, [history])

  return website
}

export const CustomValue = props => {
  const website = useContext(CustomContext)

  const images = get(website, 'customize.images')

  const findImage = id => images.find(image => image._id === id)

  let item
  if (website) {
    item = get(website, props.item)
  }

  let value
  if (item && item.type) {
    switch(item.type) {
      case 'image':
        if (item.options && item.options.multi) {
          value = item.value.map(id => findImage(id));
        } else {
          value = findImage(item.value[0]);
        }
        break;
      case 'text':
      default: value = item.value
    }
  }

  if (!website) {
    return <LoaderWrapper><Loader size="5rem" /></LoaderWrapper>
  }

  return props.render(value)
}

export const CustomProvider = props => {

  const website = useWebsite()

  return (
    <CustomContext.Provider value={website}>
      {props.children}
    </CustomContext.Provider>
  )
}