import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import Theme from 'lij/theme'
import GlobalStyle from 'lij/GlobalStyle'
import BG from 'lij/Background'
import Loader from 'lij/Loader'
import { CustomProvider } from 'lij/Custom'

import theme from 'frame/theme'

const Admin = (
  lazy(() => (
    import(/* webpackChunkName: "Admin" */ 'Admin')
  ))
)

const Home = (
  lazy(() => (
    import(/* webpackChunkName: "Home" */ 'pages/Home')
  ))
)

const About = (
  lazy(() => (
    import(/* webpackChunkName: "About" */ 'pages/About')
  ))
)

const Work = (
  lazy(() => (
    import(/* webpackChunkName: "Work" */ 'pages/Work')
  ))
)

const Sandbox = (
  lazy(() => (
    import(/* webpackChunkName: "Sandbox" */ 'pages/Sandbox')
  ))
)

const Wrapper = styled(BG).attrs({
  background: 'frame.background'
})`
  overflow: scroll;
  height: 100%;
  min-height: var(--app-height);
`

function App() {
  return (
    <HelmetProvider>
      <Theme theme={theme}>
        <GlobalStyle />
        <Router>
          <CustomProvider>
            <Wrapper id="main-wrapper">
            <Switch>
              <Route path="/admin">
                <Suspense fallback={<Loader.Frame />}>
                  <Admin />
                </Suspense>
              </Route>
              <Route path="/about">
                <Suspense fallback={<Loader.Frame />}>
                  <About />
                </Suspense>
              </Route>
              <Route path="/work">
                <Suspense fallback={<Loader.Frame />}>
                  <Work />
                </Suspense>
              </Route>
              <Route path="/sandbox">
                <Suspense fallback={<Loader.Frame />}>
                  <Sandbox />
                </Suspense>
              </Route>
              <Route path="/">
                <Suspense fallback={<Loader.Frame />}>
                  <Home />
                </Suspense>
              </Route>
              <Redirect to="/" />
            </Switch>
            </Wrapper>
          </CustomProvider>
        </Router>
      </Theme>
    </HelmetProvider>
  )
}

export default App
