class Color {
  constructor(values) {
    Object.assign(this, values)
  }

  toString() {
    return this.base
  }
}

export default Color