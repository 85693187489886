import React, { useContext } from 'react'
import { ThemeProvider, ThemeContext } from 'styled-components'
import * as colors from 'lij/colors'

const defaultTheme = {
  colors,
  text: {
    span: {
      display: 'inline',
    },
    h1: {
      size: '3.8rem', weight: '600', margin: '2rem 0'
    },
    h2: {
      size: '2.4rem', weight: '600', margin: '2rem 0'
    },
    p: {
      size: '1.8rem', lineHeight: '2.3rem', margin: '1rem 0'
    },
    label: {

    },
    a: {
      display: 'inline',
      color: 'blue',
    },
    button: {
      background: 'blue',
      color: 'white',
      transform: 'capitalize',
    }
  },
  frame: {
    background: {
      color: 'red',
      position: 'top',
      size: 'contain',
    },
    page: {
      maxWidth: '84rem',
      background: 'white',
      shadow: 'black@.5',
      borderRadius: '0.5rem',
      margin: '0 auto 2rem',
      padding: '2rem',
      wrapperPadding: '0 2rem',
      title: {
        shadow: 'clear',
        margin: '0',
      }
    },
    header: {
      gridGap: '1rem',
      padding: '1rem 2rem 1rem',
      scrolled: {
        background: 'white',
        shadow: 'black',
        title: {
          color: 'black',
        },
        logo: {
          color: 'black'
        },
        menu: {
          color: 'grey.dark'
        }
      },
      unscrolled: {
        background: 'clear',
        title: {
          color: 'white',
        },
        logo: {
          color: 'white'
        },
        menu: {
          color: 'white'
        }
      },
      title: {
        spacing: '.5rem',
        size: "1.4rem",
        transform: "uppercase",
        align: "left",
      },
      menu: {
        size: '3rem',
      },
      logo: {
        size: '3rem',
      }
    },
    menu: {
      background: 'orange',
      stickyTop: '8rem',
      gridGap: '2rem',
      button: {
        enabled: {
          background: { color: 'red' },
          shadow: 'black',
          size: '2rem',
          color: 'white',
        },
        disabled: {
          background: 'red@.5',
          shadow: 'black',
          size: '2rem',
          color: 'orange',
        },
      },
      closeButton: {
        color: 'red',
        background: 'clear',
      }
    },
    builtBy: {
      logo: 'black',
      overtext: 'black@.75',
      company: 'black',
      undertext: 'black@.5',
    }
  },
  components: {
    loader: {
      color: 'black',
    }
  }
}

const getTheme = () => defaultTheme

const Theme = props => {
  const currentTheme = useContext(ThemeContext) || defaultTheme
  const { theme:userTheme=getTheme } = props
  const theme = typeof userTheme === 'function' ? userTheme(currentTheme) : userTheme
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}

export default Theme
